<template>
  <b-card no-body>
    <b-card-header>
      <!-- title and subtitle -->
      <div>
        <b-card-title class="font-weight-bolder">
          {{ $t("message.chart.data") }}
        </b-card-title>
        <b-card-sub-title class="mt-25">
          {{ $t("message.chart.dataDesc") }}
        </b-card-sub-title>
      </div>
      <!--/ title and subtitle -->

      <div class="d-flex align-items-center">
        <v-select
          v-model="organization"
          :options="organizationOptions"
          :reduce="(val) => val.value"
          style="width: 350px"
          :clearable="false"
          @input="loadChartData(organization)"
          v-if="restrictions('select_organization_data_chart')"
        />
      </div>
    </b-card-header>
    <b-card-body>
      <vue-apex-charts
        type="donut"
        :height="chart.height"
        :options="chartOptions"
        :series="series"
      />
      <div class="float-md-right d-none d-md-block">
        <h4 class="d-flex mt-2">
          {{ $t("dataLimitMb") + ": " + dataLimitMb + "mb" }}
        </h4>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BCardSubTitle,
  BCardTitle,
} from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import flatPickr from "vue-flatpickr-component";
import vSelect from "vue-select";
import axios from "@axios";
import URL_API from "@core/utils/env";
import getHeader from "@core/utils/auth";
import moment from "moment";
import store from "@/store";
import AccessControl from "@core/utils/access-control";

export default {
  components: {
    VueApexCharts,
    BCard,
    BCardHeader,
    flatPickr,
    BCardBody,
    BCardSubTitle,
    BCardTitle,
    vSelect,
  },

  data() {
    return {
      chart: {},
      series: [],
      chartOptions: {},

      chartData: [0, 0, 0, 0],

      chartTimestamp: "",

      userData: store.state.user.userData,

      organization: "",
      organizationOptions: [],

      dataLimitMb: "",
      dataUsageMb: "",
      dataRemainingMb: "",
    };
  },

  async created() {
    await this.loadChartData(null);
    await this.getOrganizationData();
  },

  methods: {

    restrictions(value) {
      return AccessControl(value);
    },

    async getOrganizationData() {
      var organizationName = [];
      var organizationId = [];

      await axios
        .get(`${URL_API}organization/all`, {
          headers: getHeader(this.userData),
        })
        .then((response) => {
          for (let i = 0; i < response.data.length; i++) {
            const element = response.data[i].company;
            const id = response.data[i].tenant;
            organizationName.push(element);
            organizationId.push(id);
            this.organizationOptions.push({
              label: organizationName[i],
              value: organizationId[i],
            });
          }
        });
    },

    async loadChartData(selectOrganization) {
      var tenant = this.userData.tenant;

      if (selectOrganization != null) {
        tenant = selectOrganization;
      }

      var vm = this;

      await axios
        .get(`${URL_API}chart/data/all/${tenant}`, {
          headers: getHeader(this.userData),
        })
        .then((response) => {
          var string = response.data.chartInfo;
          var clear = string.slice(1, -1);
          this.chartData = clear.split(",");

          this.dataUsageMb = this.chartData[0];
          this.dataLimitMb = this.chartData[1];
          this.dataRemainingMb = this.chartData[2];

          if (string != null) {
            this.chartTimestamp = response.data.timestamp;
          }
        });

      this.chart = {
        height: "500",
      };

      this.series = [Number(this.dataUsageMb), Number(this.dataRemainingMb)];

      this.chartOptions = {
        labels: [
          this.getLabelBasic("dataUsage"),
          this.getLabelBasic("dataRemainingMb"),
        ],

        dataLabels: {
          enabled: true,
        },

        tooltip: {
          y: {
            formatter: (value) => {
              return value + "mb";
            },
          },
        },

        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                positon: "bottom",
                show: false,
              },
            },
          },
        ],

        colors: ["#feb019", "#00e396"],

        legend: {
          show: true,
          position: "top",
        },

        title: {
          text: vm.localeDate(this.chartTimestamp),
          align: "right",
          margin: 10,
          offsetX: 0,
          offsetY: 25,
          floating: false,
          style: {
            fontSize: "12px",
            fontWeight: "normal",
          },
        },
      };
    },

    getLabel(colName) {
      return this.$i18n.t("message.chart." + colName);
    },

    getLabelBasic(colName) {
      return this.$i18n.t(colName);
    },

    localeDate(value) {
      var newFormat = moment(value, "ddd MMM DD HH:mm:ss z yyyy").format(
        "MM-DD-YYYY"
      );
      var newHour = moment(value, "ddd MMM DD HH:mm:ss z yyyy").format(
        "HH:mm:ss"
      );

      if (newFormat != "Invalid date" && newFormat != null) {
        return (
          this.$i18n.d(
            new Date(newFormat.split("-")),
            "short",
            this.$i18n.locale
          ) +
          " - " +
          newHour
        );
      }
      return this.$i18n.t("message." + "noDate");
    },

    getColLabel(colName) {
      return this.$i18n.t("message.chart." + colName);
    },

    currencyValue(value) {
      return this.$i18n.n(value, "currency");
    },
  },
};
</script>
